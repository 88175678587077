<template id="">
  <!-- <h1>新規登録</h1>
  <dl class="">
    <dt>メールアドレス:</dt>
    <dd><input type="text" name="" v-model="mail" style="border:1px 1px 1px 1px;padding:0px 0px 0px 0px;width:200px;" @keydown.prevent.enter></dd>
    <dt>パスワード:</dt>
    <dd>
      <form class="fieldPassword" style="display:inline-block">
        <input type="password" class="textPassword" v-model="pw" style="border:none" @keydown.prevent.enter>
        <span id="buttonEye0" class="fa fa-eye" @click="pushHideButton(0)">
          <link href="https://use.fontawesome.com/releases/v5.6.1/css/all.css" rel="stylesheet">
        </span>
      </form>
    </dd>
    <dt>確認用パスワード:</dt>
    <dd>
      <form class="fieldPassword" style="display:inline-block">
        <input type="password" class="textPassword" v-model="re_pw" style="border:none" @keydown.prevent.enter="submit">
        <span id="buttonEye1" class="fa fa-eye" @click="pushHideButton(1)">
          <link href="https://use.fontawesome.com/releases/v5.6.1/css/all.css" rel="stylesheet">
        </span>
      </form>
    </dd>
  </dl>

  <button type="button" name="button" @click="$router.back()">戻る</button>
  <button type="button" name="button" @click="submit">登録</button> -->

  <div>
    <div class="login">
      <h3>
        <img
          class="logo"
          src="@/img/logo.png"
          alt="インテリジェントヘルスケア ストレスチェックApp"
        />
      </h3>
      <h3 class="uk-text-background">新規登録</h3>
      <div v-show="$store.state.common.sIsLoading">Loading...</div>
      <div
        v-show="
          !$store.state.common.sIsLoading && !$store.state.common.sDataLoading
        "
      >
        <div class="uk-flex uk-flex-center">
          <div class="uk-container">
            <div class="uk-text-center" uk-grid>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <label class="uk-form-label uk-text-muted"
                    >メールアドレス</label
                  >
                  <input class="uk-input" type="text" v-model="mail" />
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <label class="uk-form-label uk-text-muted">パスワード</label>
                  <input class="uk-input" :type="vInputType" v-model="pw" />
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <label class="uk-form-label uk-text-muted"
                    >パスワード(確認用)</label
                  >
                  <input class="uk-input" :type="vInputType" v-model="re_pw" />
                  <span uk-icon="icon: file-text" @click="doSwitchPassword"
                    >パスワードを表示する</span
                  >
                </div>
              </div>
              <div class="uk-width-1-2">
                <div class="uk-margin">
                  <button
                    :disabled="$store.state.common.sServerProcessing"
                    class="uk-button uk-button-default uk-width-1-1"
                    type="button"
                    @click="$router.back()"
                  >
                    &lt;&nbsp;戻る
                  </button>
                </div>
              </div>
              <div class="uk-width-1-2">
                <div class="uk-margin">
                  <button
                    :disabled="$store.state.common.sServerProcessing"
                    class="uk-button uk-button-primary uk-width-1-1"
                    type="button"
                    @click="submit"
                  >
                    登録
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p style="color: red">
        <b>{{ vMessage }}</b>
      </p>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      mail: "",
      pw: "",
      re_pw: "",
      flg: true,
      do_something: true,
      tmp: "",
      vInputType: "password",
    };
  },
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.title = "新規登録";
  },
  created() {
    this.$store.state.common.sIsLoading = true;
    this.$store.state.common.sDataLoading = false;
  },
  methods: {
    doSwitchPassword() {
      if (this.vInputType == "password") {
        this.vInputType = "text";
      } else {
        this.vInputType = "password";
      }
    },
    submit() {
      if (!this.do_something) return;
      this.do_something = false;
      this.$store.commit("common/doStartServerProcess");

      if (this.mail.length > 0 && this.pw.length > 0 && this.re_pw.length > 0) {
        if (this.pw == this.re_pw) {
          let createHash = require("sha256-uint8array").createHash;
          this.tmp = createHash().update(this.pw).digest("hex");
          console.log(this.tmp);
          this.$axios
            .post("/client/sign_up", {
              email: this.mail,
              pw: this.tmp,
            })
            .then((response) => {
              //eslint-disable-line
              //成功したときの処理
              if (response.data.flg) {
                alert("仮登録が完了しました");
                this.$store.commit("common/doEndServerProcess");
                this.do_something = true;
                this.$router.push("/approval/");
              } else {
                if (response.data.status == 1) {
                  alert("既に登録済みのメールアドレスです");
                } else {
                  alert("登録に失敗しました");
                }
              }
              this.$store.commit("common/doEndServerProcess");
              this.do_something = true;
            })
            .catch((error) => {
              this.$store.commit("common/doEndServerProcess");
              this.do_something = true;
              console.log(error);
            });
          this.$store.commit("common/doEndServerProcess");
          this.do_something = true;
        } else {
          alert("パスワードと確認用パスワードが一致しません");
          this.$store.commit("common/doEndServerProcess");
          this.do_something = true;
        }
      } else {
        alert("内容が入力されていません");
        this.$store.commit("common/doEndServerProcess");
        this.do_something = true;
      }
    },
    // pushHideButton(id) {
    //   if (this.flg) {
    //     this.flg = false
    //     document.getElementsByClassName("textPassword")[id].type = "password"
    //     document.getElementById("buttonEye" + id).className = "fa fa-eye"
    //   } else {
    //     this.flg = true
    //     document.getElementsByClassName("textPassword")[id].type = "text"
    //     document.getElementById("buttonEye" + id).className = "fa fa-eye-slash"
    //   }
    // }
  },
};
</script>

<style scoped>
.textPassword {
  border: none;
  /* デフォルトの枠線を消す */
}

.fieldPassword {
  border-width: thin;
  border-style: solid;
  width: 200px;
}

dl dt {
  text-align: right;
  width: 165px;
  padding: 10px 0;
  float: left;
  clear: both;
}

dl dd {
  padding: 10px 0;
}
</style>
